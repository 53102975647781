<script setup>
const route = useRoute()

const { data: blogItem } = await useApi(`/api/pages/${route.params.slug}`)

useHead({
  title: blogItem.title,
})
</script>

<template>
  <div class="flex-center my-8 flex w-screen px-4 md:px-20">
    <div class="container">
      <div class="flex flex-row justify-between">
        <div />
      </div>

      <div class="mx-auto max-w-85ch">
        <EditorWrapper
          v-if="blogItem?.content"
          :body="blogItem?.content"
        />
        <EditorWrapper
          v-else-if="blogItem?.body"
          :body="blogItem?.body"
        />
      </div>
    </div>
  </div>

  <div
    v-if="blogItem && blogItem.products && blogItem.products.length > 0"
    class="flex-center mx-auto my-16 flex max-w-85ch flex-col gap-8"
  >
    <div v-auto-animate class="grid md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
      <ProductItem
        v-for="product in blogItem.products"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<style>
body {
  overflow-x: hidden
}

#path {
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.blog-text > * {
  margin-bottom: 20px;
}
</style>
